import React from "react"
import "./layout.css"
import { useStaticQuery, Link, graphql } from "gatsby"


const ListLink = props => (
  <li style={{ display: `inline-block`, marginRight: `1rem` }}>
    <Link activeClassName={`active`} to={props.to}>{props.children}</Link>
  </li>
)

export default function Layout({ children }) {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  return (
    <div className="flex flex-col h-screen">
      <div className="header sticky p-4 sm:px-10 md:px-16 nav-container">
        <Link to="/" style={{ textShadow: `none`, backgroundImage: `none` }}>
          <h3 className="logo text-3xl sm:text-2xl" style={{ display: `inline` }}>{data.site.siteMetadata.title}</h3>
        </Link>
        <ul className="text-gray-600 text-sm " style={{ listStyle: `none` }}>
          <ListLink to="/">O mnie</ListLink>
          <ListLink to="/offers/">Oferta</ListLink>
          <ListLink to="/gallery/">Galeria</ListLink>
          {/* <ListLink to="/newsroom/">Aktualności</ListLink> */}
          <ListLink to="/contact/">Kontakt</ListLink>
        </ul>
      </div>
      <main className="flex-grow">
        {children}
      </main>
      <footer className="p-4 sm:px-10 md:px-16 bg-gray-200">
        <div className="p-0 md:py-8 lg:py-12 flex-grow mx-auto">
          <div className="">
            <ul className="text-xs py-0.5" style={{ listStyle: `none`, }}>
              <ListLink to="/">O mnie</ListLink>
              <ListLink to="/offers/">Oferta</ListLink>
              <ListLink to="/gallery/">Galeria</ListLink>
              {/* <ListLink to="/newsroom/">Aktualności</ListLink> */}
              <ListLink to="/contact/">Kontakt</ListLink>
            </ul>
          </div>
          <hr className="bg-gray-300 h-0.5"></hr>
          <div className="text-gray-500 text-xs">
            <p className="py-0.5">Wszelkie prawa zastrzeżone © 2021 Hania Szyca Fotografia.</p>
          </div>
        </div>
      </footer>
    </div>)
}
